/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import Fade from 'react-reveal/Fade';

export default function Advantage({ data }) {
  return (
    <div className="bg-gray-50 py-20 mb-24 sm:mb-18 xl:mb-16">
      <div className="container mx-auto">
        <Fade bottom>
          <h1 className="text-4xl text-theme-blue text-center font-bold">Why us</h1>

          <p className="font-light text-lg text-gray-400 text-center mb-12 sm:mb-5 xl:mb-0">
            We are a team of experienced software engineers on a mission to revolutionize businesses
            with innovative solutions.
          </p>
        </Fade>

        <div className="flex flex-col items-center">
          <div className="bg-orange-500 text-white flex flex-col sm:flex-row justify-between items-center p-6 rounded-2xl shadow-xl w-full mx-3 my-6">
            <div className="flex flex-col items-center sm:items-start">
              <div className="flex flex-col items-center">
                <h2 className="text-3xl font-bold text-black">15+</h2>
                <p className="font-light text-lg text-gray-400">Projects Completed</p>
              </div>
            </div>
            <div className="flex flex-col items-center sm:items-start">
              <div className="flex flex-col items-center">
                <h2 className="text-3xl font-bold text-black">12+</h2>
                <p className="font-light text-lg text-gray-400">Happy Clients</p>
              </div>
            </div>
            <div className="flex flex-col items-center sm:items-start">
              <div className="flex flex-col items-center">
                <h2 className="text-3xl font-bold text-black">100K+</h2>
                <p className="font-light text-lg text-gray-400">Market Community</p>
              </div>
            </div>
            <div className="flex flex-col items-center sm:items-start">
              <div className="flex flex-col items-center">
                <h2 className="text-3xl font-bold text-black">4+</h2>
                <p className="font-light text-lg text-gray-400">Years of Experience</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
