// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBMM77_L_yvcpyF4hzeDi76QmTRT504WgY',
  authDomain: 'innovita-soft.firebaseapp.com',
  projectId: 'innovita-soft',
  storageBucket: 'innovita-soft.appspot.com',
  messagingSenderId: '566399528938',
  appId: '1:566399528938:web:705cd5df3fb6a4b8644c93',
  measurementId: 'G-28KPR458H7',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
export { app, db, analytics };
