/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Fade from 'react-reveal/Fade';
import Button from 'elements/Button';
import BuildWebsite from 'assets/images/Startup.png';

export default function Hero() {
  return (
    <section className="hero bg-white py-10">
      <Fade bottom>
        <div className="flex flex-col lg:flex-row items-center justify-between w-full lg:w-1/2 xl:pl-12 sm:pr-2 mt-8">
          <div className="text-center lg:text-left">
            <h1 className="text-3xl sm:text-4xl text-theme-blue font-bold leading-tight mb-5">
              We specialize in custom Website Development,
              <br />
              Mobile App Solutions, and AI Automations.
            </h1>
            <p className="font-light text-lg text-gray-600 leading-relaxed mb-8">
              We provide developers & designers using the latest technologies
              to help you scale up your business.
            </p>
            <Button
              href="/project"
              type="link"
              className="flex items-center px-8 py-4 text-white text-lg bg-theme-purple rounded-lg shadow-lg hover:bg-dark-theme-purple transition duration-200"
            >
              See Our Work
              <svg className="ml-2 w-6 h-6 text-white animate-bounce-x" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </Button>
          </div>
        </div>
      </Fade>

      <Fade bottom>
        <div className="flex pt-5 w-full justify-center items-center lg:w-1/2">
          <img className="max-w-full h-auto rounded-lg shadow-md" src={BuildWebsite} alt="Build Website" />
        </div>
      </Fade>
    </section>
  );
}
